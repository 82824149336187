.main-header {
  position: relative;
  padding-top: 50px;
  padding-bottom: 75px;

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: 50%;
    z-index: 1;
    width: 1366px;
    height: 586px;
    transform: translate(-50%, -50%);
    background: radial-gradient(
      #131f38 0%,
      rgba(15, 22, 37, 0.11) 72.03%,
      rgba(15, 22, 36, 0) 100%
    );
    border-radius: 50%;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
  }

  &__suptext {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.12rem;
    color: #ffffff;
  }

  &__title-wrapper {
    position: relative;
    //max-width: 873px;
    //width: 100%;
    margin-top: 80px;

    @media screen and (max-width: 900px) {
      margin-top: 50px;
    }
  }

  &__title {
    display: inline;
    margin: 10px 0 0;
    font-size: 52px;
    font-weight: 400;
    line-height: 1.3;
    text-transform: uppercase;
    color: #ffffff;

    @media screen and (max-width: 900px) {
      display: inline-block;
    }

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

    @media (max-width: 600px) {
      display: inline;
      font-size: 30px;
    }

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }

    span {
      &.drupal-left-logo {
        position: relative;
        display: inline-block;
        padding-left: 78px;
        font-weight: bold;
        text-decoration: underline;
        color: #1cace4;

        @media screen and (max-width: 700px) {
          padding-left: 45px;
        }

        @media (max-width: 600px) {
          padding-left: 35px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -6px;
          transform: translateY(-50%);
          width: 76px;
          height: 76px;
          background-image: url("../img/content/icon-title-text.png");
          background-size: contain;

          @media screen and (max-width: 700px) {
            width: 45px;
            height: 45px;
          }

          @media (max-width: 600px) {
            left: 0;
            width: 30px;
            height: 30px;
          }
        }
      }

      &.title-yellow {
        color: #e5771f;
      }
    }
  }

  &__subtext {
    display: inline-block;
    margin: 0;
    font-size: 16px;
    line-height: 1.3;
    color: #ffffff;

    @media (max-width: 600px) {
      display: inline;
    }
  }

  &__btn-wrapper {
    display: flex;
    margin-top: 72px;

    @media screen and (max-width: 440px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 30px;
    }

    .main-btn {
      &:first-of-type {
        margin-right: 20px;

        @media screen and (max-width: 440px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.cause-list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 70px;
  padding: 0;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    max-width: 500px;
    margin: 50px auto 0;
  }

  &--long {
    @media screen and (max-width: 900px) {
      max-width: 700px;
    }

    .cause-list__item {
      width: 300px;
    }
  }

  &__item {
    position: relative;
    width: 210px;
    padding-left: 30px;

    @media screen and (max-width: 900px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 700px) {
      padding-left: 20px;
    }

    &:not(:first-of-type) {
      margin-left: 20px;

      @media screen and (max-width: 900px) {
        margin-left: 0;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 67px;
      transform: translateY(-50%);
      background-color: #158dcd;
    }

    &::after {
      left: 5px;
    }
  }

  &__title {
    margin-bottom: 7px;
    font-size: 23px;
    line-height: 1.2;
    color: #2ce329;
  }

  &__text {
    font-size: 16px;
    line-height: 1.2;
    color: #ffffff;
  }
}

.switch {
  display: inline-flex;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  position: absolute;
  top: 1px;
  right: 1px;

  &__item {
    padding: 0 10px;
    margin-bottom: 7px;
    font-size: 23px;
    line-height: 1.2;
    color: #ffffff;
    border-right: 1px solid #ffffff;

    &:last-of-type {
      border-right: none;
    }

    &.active {
      color: #2ce329;
    }
  }
}

.inline {
  @media (max-width: 600px) {
    white-space: nowrap;
  }
}
