.tariffs {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }

  &__title {
    position: relative;
    z-index: 2;
    font-display: swap;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }
  }

  &__list {
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 57px 0 0;
    padding: 0;
  }

  &__item {
    margin: 15px 15px 55px;
  }

  &__img-1 {
    position: absolute;
    top: 130px;
    left: -70px;
    display: block;
    z-index: 1;
  }

  &__img-2 {
    position: absolute;
    right: -320px;
    bottom: -230px;
    z-index: 1;
  }
}

.tariff {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__name {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.32rem;
    text-transform: uppercase;
    color: #4043ff;

    @media screen and (max-width: 700px) {
      margin-bottom: 25px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 270px;
    min-width: 220px;
    width: 100%;
    height: 410px;
    padding: 35px 10px 20px 30px;
    border-radius: 8px;
    background-color: #576078;
    overflow: hidden;
    transition: height 0.3s;

    @media screen and (max-width: 1024px) {
      height: 410px;
      padding-left: 20px;
    }

    &:hover {
      height: 410px;
      background-color: #3be4ce;
      padding-top: 15px;

      .tariff__price {
        color: #09131c;
        font-size: 25px;
      }

      .tariff__footnote {
        color: #09131c;
      }

      .tariff__period {
        color: #576078;
      }

      .tariff__text {
        color: #576078;
      }

      .tariff__list {
        li {
          color: #09131c;
        }
      }

      .tariff__button {
        opacity: 1;
        visibility: visible;
        top: 0;
        padding: 0 auto;
      }
    }

    &--long {
      height: 470px;

      @media screen and (max-width: 1024px) {
        height: 540px;
      }

      &:hover {
        height: 540px;
      }
    }
  }

  &__price {
    margin-bottom: 8px;
    font-weight: bold;
    line-height: 1.2;
    color: #ffffff;
    transition: color 0.3s;
    font-size: 25px;
  }

  &__period {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.2;
    color: #bdc3ca;
    opacity: color 0.3s;
  }

  &__text {
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: -0.034rem;
    color: #ffffff;
    opacity: color 0.3s;
  }

  &__list {
    flex-grow: 1;
    padding-left: 10px;
    list-style-type: "–";

    li {
      margin-bottom: 9px;
      padding-left: 5px;
      font-size: 14px;
      line-height: 1.2;
      color: #bdc3ca;
      opacity: color 0.3s;
    }
  }

  &__button {
    flex-shrink: 0;
    //max-width: 117px;
    //width: 100%;
    height: 40px;
    margin-top: 20px;
    padding: 0 17px;
    background-color: #090e18;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    position: relative;
    top: -20px;

    @media screen and (max-width: 1024px) {
      opacity: 1;
      visibility: visible;
      top: 0;
    }
  }
}

[lang="de"] {
  .tariff {
    &__footnote {
      color: #ffffff;
    }

    &__price {
      font-size: 25px;
      &-del {
        font-size: 21px;
      }
    }
  }
  .del__tariff__price {
    font-size: 21px;
  }
}

[lang="en"] {
  .tariff {
    &__footnote {
      color: #ffffff;
    }

    &__price {
      font-size: 25px;
      &-del {
        font-size: 21px;
      }
    }

    &__card {
      &:hover {
        padding-top: 35px;
      }

      &--long {
        height: 490px;
      }
    }
  }
}
