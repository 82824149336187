.discount {
  position: relative;
  padding-bottom: 150px;

  @media screen and (max-width: 1880px) {
    padding-bottom: 12vw;
  }

  @media screen and (max-width: 1023px) {
    padding-bottom: 150px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 100px;
  }

  &__img-bg {
    position: absolute;
    left: 50%;
    top: -110px;
    z-index: 1;
    max-width: 1820px;
    min-width: 1100px;
    width: 132vw;
    transform: translateX(-50%);

    @media screen and (max-width: 1023px) {
      top: 100px;
    }

    @media screen and (max-width: 600px) {
      top: 170px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    max-width: 1170px;
    margin: 0 auto;
    padding: 50px 110px 50px 15px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .8);

    @media screen and (max-width: 1200px) {
      padding-right: 20px;
    }

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      padding-right: 20px;
      padding-left: 20px;
    }

    @media screen and (max-width: 640px) {
      margin: 0 -10px;
      padding-right: 10px;
      padding-left: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 36px;
      left: 43px;
      width: 20px;
      height: 20px;
      background-color: #ef9f4d;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 75px;

    @media screen and (max-width: 1023px) {
      padding-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 34px;
      height: 202px;
      width: 2px;
      background: #158DCD;
      transform: translateY(-50%);

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__img {
    flex-shrink: 0;
    display: block;
  }

  &__title {
    max-width: 745px;
    margin-bottom: 15px;
    font-display: swap;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;

    span {
      text-transform: uppercase;
      color: #1cace4;
    }

    @media screen and (max-width: 640px) {
      font-size: 24px;
    }

    @media screen and (max-width: 640px) {
      font-size: 24px;
    }

    @media screen and (max-width: 400px) {
      font-size: 19px;
    }
  }

  &__descr {
    margin: 0;
    margin-bottom: 20px;
    font-size: 23px;

    @media screen and (max-width: 400px) {
      font-size: 16px;
    }

    span {
      font-weight: bold;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text {
    flex-grow: 1;
    max-width: 410px;
    margin: 0;
    margin-right: 20px;

    @media screen and (max-width: 640px) {
      margin: 0;
    }

    @media screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .main-btn {
    margin-left: auto;
    padding: 0 32px;
    text-transform: uppercase;
    font-weight: bold;

    @media screen and (max-width: 640px) {
      margin: 20px 0 0;
    }
  }
}
