h1.article-page__title {
  font-size: 38px;

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }

}

.article {
  margin: 0;
  padding: 0;
  min-height: 200px;
  padding: 20px;
  font-family: Montserrat;
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 800px) {
    padding: 0px;
  }

  & h2 {
    font-display: swap;
    font-size: 18px;
    color: #ffff;
  }

  & h3 {
    font-display: swap;
    font-size: 16px;
    color: #ffff;
  }

  & p {
    width: auto;
    color: azure;
    margin-bottom: 0;
    margin-top: 5px;
  }

  & li {
    color: azure;
  }

  & b {
    font-weight: 400;
    line-height: 1.3;
    color: #e5771f;

    @media (max-width: 500px) {
      display: block;
    }
  }

  & figcaption {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
    font-style: italic;
    display: block;
  }

  & em {
    font-weight: 400;
    line-height: 1.3;
    color: azure;

    @media (max-width: 500px) {
      display: block;
    }
  }

  & div.picture-wrap {
    text-align: center;
    & img {
      color: azure;
    }
  }

  & div.header-author{

    text-align: left;
    margin-bottom: 5px;

     @media screen and (max-width: 800px) {
          white-space: nowrap;
          bottom: 15px;
          left: 20px;
          width: 40px;
        }

        & img {
          max-width: 100%;
          height: auto;
          border-radius: 50%;
          color:azure;
        }
  }


  & div.picture-header-wrap {
    text-align: left;
    & img {
      color: azure;
    }
  }

  & a {
    margin-bottom: 7px;
    line-height: 1.2;
    color: #2ce329;
    text-decoration: underline;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
    }
  }

  & video {
    background: none;
  }

  & pre code {
    border: none;
    background: #131f38;
    border-left: 3px solid #2ce329;
    color: #ddd;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }

  & code {
    background: #131f38;
    border: 1px solid #2ce329;
    color: #2ce329;
    padding: 2px 4px;
  }
}
