.contacts {
  color: #fff;

  &__item {
    margin-top: 30px;

    &-title {
      font-display: swap;
      font-size: 36px;
      line-height: 1.2;
      color: #fff;
      margin: 0;

      @media (max-width: 700px) {
        font-size: 32px;
      }

      @media (max-width: 600px) {
        font-size: 28px;
      }

      @media (max-width: 500px) {
        font-size: 21px;
      }
    }

    &-actions {
      margin-bottom: 10px;

      a {
        margin-bottom: 7px;
        font-size: 23px;
        line-height: 1.2;
        color: #2ce329;
        text-decoration: underline;
        transition: all 0.3s;

        &:hover {
          text-decoration: none;
        }

        @media (max-width: 500px) {
          font-size: 18px;
        }
      }
    }

    &-text {
      margin-bottom: 10px;

      p {
        margin-bottom: 0;
        margin-top: 5px;
      }

      b {
        font-size: 23px;
        font-weight: 400;
        line-height: 1.3;
        color: #e5771f;

        @media (max-width: 500px) {
          font-size: 18px;
          display: block;
        }
      }
    }

    &-map {
      margin-top: 30px;
    }
  }
}
