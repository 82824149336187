.get {
  position: relative;
  //height: 100vh;
  background: linear-gradient(-125deg, #ff1414 0%, #9b7878 100%);

  &__wrapper {
    padding-top: 95px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .b24-form-header-title {
      font-family: "Montserrat","Arial",sans-serif;
      max-width: 570px;
      margin: 5px auto 10px;
      font-weight: bold;
      font-size: 36px;
      line-height: 1.2;
      text-align: center;
      color: #ffffff;

      @media screen and (max-width: 700px) {
        font-size: 30px;
      }

      @media screen and (max-width: 420px) {
        font-size: 26px;
      }
    }

    .b24-form-header-description {
      font-family: "Montserrat","Arial",sans-serif;
      margin: 0;
      text-align: center;
      color: #ffffff;
      font-size: 16px;
      opacity: 1;
      line-height: 22px;
    }

    .b24-form-wrapper.b24-form-shadow.b24-form-border-bottom {
      background: transparent;
      box-shadow: none;
      border: none;
      font-size: 16px;
      max-width: 100%;
    }

    .b24-form-header {
      margin-bottom: 50px;
      padding-left: 0;
      padding-right: 0;
      border-bottom: none;
    }

    .b24-form-field.b24-form-field-email {
      position: relative;
      margin-bottom: 24px;
     
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 22px;
        height: 15px;
        background-image: url(../img/sprite/icon-email-white.svg);
        -webkit-transition: background-image 0.3s;
        transition: background-image 0.3s;
      }
    }

    .b24-form-field-agreement-link {
      color: #fff;
    }

    .b24-form-control-required,
    .b24-form-sign {
      display: none;
    }

    .b24-form-control-alert .b24-form-control-alert-message {
      display: none;
    }

    .b24-form-control-string {
      .b24-form-control {
        color: #fff;
        width: 100%;
        height: 50px;
        padding-left: 36px;
        border: 0;
        border-bottom: 2px solid #fff;
        border-radius: 0;
        background: 0 0;
        outline: 0;
        -webkit-transition: border-bottom-color .3s;
        transition: border-bottom-color .3s; 
        padding-top: 0; 

        :-webkit-autofill {
          color: #fff;
          box-shadow: none;
          background: transparent;
        }

        &:focus {
          background: transparent;

          +.b24-form-control-label {
            transform: none;
            top: 15px;
            font-size: 15px;
          }
        }
      }

      .b24-form-control-label {
        position: absolute;
        top: 15px;
        left: 36px;
        font-weight: 300;
        font-family: "Montserrat","Arial",sans-serif;
        font-size: 15px;
        color: #fff;
        opacity: .5;
        pointer-events: none;
        -webkit-transition: top .3s,color .3s,opacity .3s;
        transition: top .3s,color .3s,opacity .3s;
        padding: 0;
        transform: none;
      }

      &.b24-form-control-alert {
        .b24-form-control {
          background-color: transparent;
          border-color: red;
        }
      }  
    }

    .b24-form-content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 545px;
      width: 100%;
      margin: 55px auto 0;
      padding: 0;
    }

    .b24-form-btn-block {
      text-align: center;
    }

    .b24-form-btn {
      height: 60px;
      padding: 0 45px;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1.2;
      outline: 0;
      -webkit-transition: opacity .3s;
      transition: opacity .3s;
      cursor: pointer;
      font-family: "Montserrat","Arial",sans-serif;
      -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.16);
      box-shadow: 0 3px 6px rgba(0,0,0,.16);
      background-color: #fff;
      font-weight: 600;
      color: #d93b3b;
      display: inline-block;
      width: auto;
      margin-left: auto;
      margin-right: auto; 
    }
  }

  &__icon {
    margin-bottom: 30px;
  }

  &__title {
    max-width: 570px;
    margin: 5px 0 10px;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 420px) {
      font-size: 26px;
    }

    span {
      text-decoration: underline;
      color: #C2F231;
    }
  }

  &__descr {
    margin: 0;
    text-align: center;
    color: #ffffff;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 545px;
    width: 100%;
    margin-top: 55px;

    .input-wrapper {
      &__icon {
        use {
          fill: #ffffff;
        }
      }

      input {
        border-bottom-color: #ffffff;
        color: #ffffff;
      }

      label {
        color: #ffffff;
      }
    }

    .main-btn {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      font-weight: 600;
      color: #D93B3B;
    }
  }
}
