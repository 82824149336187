@import "../../../node_modules/slick-carousel/slick/slick.scss";

.clients__list {
  padding: 30px 50px 30px;
  margin: 20px;
  position: relative;
  user-select: auto;
}
.clients__item {
  padding: 40px 40px;

  & img {
    margin: 0 auto;
    margin-bottom: 3%;
  }

  & figcaption {
    width: 600px;
    max-width: 100%;
    margin: auto;
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: none;
  background: transparent;
  font-size: 0;
  z-index: 10;

  &::before {
    display: table;
    font-size: 60px;
    @media screen and (max-width: 600px) {
      font-size: 40px;
    }
  }
}
.slick-prev {
  left: 0;

  &::before {
    content: "\276E";
  }

  @media screen and (max-width: 600px) {
    left: 10%;
    transform: none;
    bottom: 0;
    top: auto;
  }
}
.slick-next {
  right: 0;

  &::before {
    content: "\276F";
  }

  @media screen and (max-width: 600px) {
    right: 10%;
    transform: none;
    bottom: 0;
    top: auto;
  }
}

.clients__item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  .clients__item-wrapper {
    display: block;
  }

  div.clients__item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 1280px) {
  .clients__item-wrapper {
    display: block;
  }

  div.clients__item {
    padding: 40px 64px;
  }
}
