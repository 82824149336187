.offer {
  position: relative;
  padding-top: 400px;
  background-color: #fff;

    @media (max-width: 1000px) {
      padding-top: 100px;
    }

    @media (max-width: 600px) {
      padding-top: 50px;
    }

  &__inner {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    position: relative;
    z-index: 3;
  }

  &__title {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    color: #010002;
  }

  &__descr {
    font-size: 14px;
    text-align: center;
    color: #6B6C7E;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 707px;
    width: 100%;
    margin: 40px auto 0;

    .main-btn {
      margin: 50px auto 0;
      font-weight: bold;
    }
  }

  &__form-privacy {
    position: relative;
    margin: 25px 0 0;
    padding-right: 20px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.07rem;
    opacity: 0.5;
    color: #0F3857;
    transition: opacity 0.3s;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 13px;
      height: 8px;
      background-image: url(../img/svg/icon-check.svg);
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }

    p {
      margin: 0;
    }

    a {
      text-decoration: underline;
      color: #0F3857;
    }
  }

  &__form.b24 {
    max-width: initial;
    margin: 0;

    .b24-form {
      .b24-form-wrapper {
        box-shadow: none;
        border: none;
        font-family: "Montserrat","Arial",sans-serif;
        width: 100%;
        max-width: 100%;
        background: transparent;
      }

      &-state-container {
        .b24-form-success {
          background-color: #fff;
        }
      }

      &-sign {
        display: none;
      }

      &-btn {
        margin: 8px auto 0;
        font-weight: 700;
        display: inline-block;
        font-family: "Montserrat","Arial",sans-serif;
        height: 60px;
        padding: 0 45px;
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.2;
        outline: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
        cursor: pointer;
        width: auto;

        &-block {
          text-align: center;
        }
      }

      &-header {
        padding: 0;
        border: none;

        &-title {
          margin: 0 0 20px;
          font-weight: 800;
          font-size: 36px;
          line-height: 1.2;
          text-align: center;
          color: #010002;
          font-family: "Montserrat","Arial",sans-serif;

          @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }

        }

        &-description {
          font-size: 14px;
          text-align: center;
          color: #6b6c7e;
          opacity: 1;
          margin-top: 14px;
          margin-bottom: 14px;
          line-height: 22px;
          font-family: "Montserrat","Arial",sans-serif;
        }
      }

      &-content {
        max-width: 707px;
        width: 100%;
        margin: 40px auto 0;
        padding: 0;
      }

      &-control {
        &-container {
          margin-bottom: 35px;
        }

        &-agreement {
          position: relative;
          margin: 25px 0 0;
          padding-right: 20px;
          font-size: 12px;
          line-height: 22;
          text-align: center;
          letter-spacing: .07rem;
          opacity: .5;
          color: #0f3857;
          -webkit-transition: opacity .3s;
          transition: opacity .3s;
          font-family: "Montserrat","Arial",sans-serif;

          .b24-form-field-agreement-link {
            font-family: "Montserrat","Arial",sans-serif;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: .07rem;
          }

          .b24-form-control-desc {
            opacity: 1;
          }

          input {
            position: absolute;
            top: 50%;
            right: -20px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }

        &-string {
          position: relative;

          &.b24-form-field-email {
            &:before {
              content: "";
              width: 22px;
              height: 15px;
              background-image: url(../img/sprite/icon-email.svg);
              -webkit-transition: background-image .3s;
              transition: background-image .3s;
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }

          &.b24-form-field-name {
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              background-image: url(../img/sprite/icon-user-blue.svg);
              -webkit-transition: background-image .3s;
              transition: background-image .3s;
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }

          input {
            width: 100%;
            height: 50px;
            padding-left: 36px;
            padding: 1px 0 1px 36px;
            border: 0;
            border-bottom: 2px solid #014f80;
            border-radius: 0;
            background: 0 0;
            outline: 0;
            -webkit-transition: border-bottom-color .3s;
            transition: border-bottom-color .3s;
          }

          &.b24-form-control-alert {
            input {
              border-color: red;
            }
          }
        }

        &-alert-message {
          display: none;
        }

        &-required {
          display: none;
        }

        &-label {
          font-weight: 300;
          font-size: 15px;
          color: #014f80;
          opacity: .5;
          pointer-events: none;
          -webkit-transition: top .3s,color .3s,opacity .3s;
          transition: top .3s,color .3s,opacity .3s;
          padding: 0;
          font-family: "Montserrat","Arial",sans-serif;
          position: absolute;
          top: 15px;
          left: 36px;
          line-height: 22px;
          transform: none;
        }

        &:focus {
          +.b24-form-control-label {
            font-size: 15px;
            top: 15px;
            left: 36px;
            transform: none;
            opacity: 0.1;
          }
        }
      }
    }
  }
  }


.input-wrapper {
  position: relative;
  width: 100%;

  &:focus,
  &:hover {
    outline: none;
  }

  &:first-of-type {
    margin-bottom: 35px;
  }

  input {
    width: 100%;
    height: 50px;
    padding-left: 36px;
    border: none;
    border-bottom: 2px solid #014F80;
    border-radius: 0;
    background: transparent;
    outline: none;
    transition: border-bottom-color 0.3s;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  label {
    position: absolute;
    top: 15px;
    left: 36px;
    font-weight: 300;
    font-size: 15px;
    color: #014F80;
    opacity: 0.5;
    pointer-events: none;
    transition: top 0.3s, color 0.3s, opacity 0.3s;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    use {
      fill: #80A7BF;
      transition: fill;
    }
  }

  &--focus {
    input {
      border-bottom-color: #4042FB;
    }

    label {
      top: 52px;
      color: #4042FB;
      opacity: 1;
    }

    .input-wrapper__icon {
      use {
        fill: #4042FB;
      }
    }
  }

  &--error {
    input {
      border-bottom-color: red !important;
    }
  }
}
