.qualified {
  position: relative;
  max-width: 1300px;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 18%;
    top: 20px;
    z-index: 0;
    max-width: 1021px;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      #131f38 0%,
      rgba(15, 22, 37, 0.11) 72.03%,
      rgba(15, 22, 36, 0) 100%
    );
  }

  &__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    //height: 318px;
    margin-left: -90px;
    padding: 70px 125px 90px 120px;
    border: 1px dashed rgba(#797afc, 0.34);
    border-radius: 28px;
    background-color: rgba(#131924, 0.34);

    @media screen and (max-width: 700px) {
      padding: 30px 50px 30px 120px;
    }
  }

  &__title {
    position: relative;
    margin-top: 0;
    margin-bottom: 10px;
    font-display: swap;
    font-weight: 400;
    font-size: 52px;
    letter-spacing: -0.056rem;
    line-height: 1.2;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 30px;
      line-height: 1;
    }

    span {
      font-weight: bold;
      color: #1cace4;
    }
  }

  &__bottom {
    position: relative;
    z-index: 2;

    @media screen and (max-width: 780px) {
      flex-direction: column;
    }
  }

  &__text,
  &__text a {
    flex-grow: 1;
    margin: 0;
    color: #ffffff;
    margin-bottom: 20px;
  }

  &__wrapper-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    //max-width: 363px;
    //width: 100%;
    //margin-top: -50px;
    margin-left: auto;

    @media screen and (max-width: 780px) {
      margin: 0;
      justify-content: flex-start;
    }

    .main-btn {
      &:first-of-type {
        margin-right: 20px;
      }

      @media screen and (max-width: 780px) {
        margin-top: 20px;
      }
    }
  }

  &__terminal {
    position: absolute;
    top: -278px;
    right: -121px;
    z-index: 1;
  }
}
