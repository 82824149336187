.technology {
  position: relative;
  padding-top: 71px;

  &__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    //height: 318px;
    margin-left: -90px;
    padding: 70px 125px 90px 120px;
    border: 1px dashed rgba(#797afc, 0.34);
    border-radius: 28px;
    background-color: rgba(#131924, 0.34);
  }

  &__title {
    position: relative;
    z-index: 2;
    margin-top: 0;
    margin-bottom: 0;
    font-display: swap;
    font-weight: bold;
    font-size: 52px;
    letter-spacing: -0.23rem;
    line-height: 1.2;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 30px;
      line-height: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: -340px;
      left: -50%;
      z-index: -1;
      width: 1021px;
      height: 757px;
      background: radial-gradient(
        #131f38 0%,
        rgba(15, 22, 37, 0.11) 72.03%,
        rgba(15, 22, 36, 0) 100%
      );
    }

    span {
      position: relative;
      z-index: 3;
    }

    .color {
      text-transform: uppercase;
      color: #1cace4;
      @media (max-width: 465px) {
        display: block;
      }
    }
  }

  &__descr {
    position: relative;
    z-index: 2;
    margin: 0;
    letter-spacing: 0.01rem;
    color: #fff;
  }

  &__list {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1100px;
    margin: 80px auto 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 40px;

    @media screen and (max-width: 1024px) {
      margin: 20px;
    }

    &:nth-child(+n+ 7) {
      margin: 40px 10px;

      @media screen and (max-width: 1024px) {
        margin: 20px;
      }
    }

    svg {
      use {
        fill: gray;
      }
    }
  }
}
