.why {
  position: relative;
  z-index: 3;
  padding-top: 120px;

  &__title {
    position: relative;
    margin-top: 0;
    margin-bottom: 10px;
    font-display: swap;
    font-weight: 400;
    font-size: 52px;
    letter-spacing: -0.056rem;
    line-height: 1.2;
    color: #FFFFFF;

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 30px;
      line-height: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: -50px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: #EF9F4D;

      @media screen and (max-width: 1024px) {
        left: 20px;
      }
    }

    .color {
      font-weight: bold;
      color: #EF9F4D;
    }

    .code-anim {
      display: inline-block;
    }
  }

  &__descr {
    margin: 0;
    letter-spacing: 0.01rem;
    color: #FFFFFF;
  }

  &__list {
    display: flex;
    margin: 55px 0 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 920px) {
     flex-direction: column;
    }
  }

  &__item {
    width: calc(100% / 5);
    height: 273px;
    padding: 45px 30px;
    color: #FFFFFF;

    @media screen and (max-width: 1300px) {
      padding: 45px 15px;
    }

    @media screen and (max-width: 1024px) {
      padding: 45px 10px;
    }

    @media screen and (max-width: 920px) {
      width: 100%;
      max-height: none;
      height: auto;
    }

    &:nth-of-type(odd) {
      background-color: #EF9F4D;
    }

    &:nth-of-type(even) {
      background-color: rgba(#EF9F4D, 0.6);

      .why__item-text {
        color: #FFFFFF;
      }
    }

    &-title {
      margin: 0 0 10px;
      font-weight: bold;
      font-size: 23px;
      line-height: 1.2;
      color: #090E17;

      @media screen and (max-width: 1024px){
        font-size: 18px;
      }
    }

    &-text {
      margin: 0;
      color: #090E17;

      @media screen and (max-width: 1200px){
        font-size: 14px;
      }
    }
  }
}
