body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: $font-family--;
  font-display: fallback;
  color: $color-default-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-default-black;
  text-decoration: none;
}

.block {
  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
  }
}
