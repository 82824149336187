.advantages {
  position: relative;
  padding-top: 80px;
  padding-bottom: 100px;
  background-image: url("../img/bg/codeeditor-bg.webp");

  &__wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding-left: 75px;

    @media screen and (max-width: 700px) {
      padding: 0 20px;
    }
  }

  &__title {
    position: relative;
    max-width: 670px;
    margin: 0;
    font-size: 36px;
    font-display: swap;
    line-height: 1.2;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: -67px;
      width: 44px;
      height: 44px;
      background-image: url("../img/svg/icon-simple-drupal.svg");

      @media screen and (max-width: 700px) {
        top: -50px;
        left: 0;
      }
    }

    span {
      color: #1cace4;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 58px 0 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    width: 50%;
    padding-right: 20px;
    padding-bottom: 40px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    &-title {
      margin-bottom: 5px;
      font-size: 23px;
      color: #2ce329;
    }

    &-text {
      color: #ffffff;
    }
  }
}
