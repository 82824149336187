@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-Light/montserrat-light.woff2") format("woff2"),
    url("../fonts/Montserrat-Light/montserrat-light.woff") format("woff");
}


@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-Regular/montserrat-regular.woff2") format("woff2"),
    url("../fonts/Montserrat-Regular/montserrat-regular.woff") format("woff");
}


@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-Medium/montserrat-medium.woff2") format("woff2"),
    url("../fonts/Montserrat-Medium/montserrat-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: bold;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-Bold/montserrat-bold.woff2") format("woff2"),
    url("../fonts/Montserrat-Bold/montserrat-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-SemiBold/montserrat-semibold.woff2") format("woff2"),
    url("../fonts/Montserrat-SemiBold/montserrat-semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-ExtraBold/montserrat-extrabold.woff2") format("woff2"),
    url("../fonts/Montserrat-ExtraBold/montserrat-extrabold.woff") format("woff");
}


@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: "Montserrat-ExtraBold";

  font-display: swap;
  src:
    url("../fonts/Montserrat-ExtraBold/montserrat-extrabold.woff2") format("woff2"),
    url("../fonts/Montserrat-ExtraBold/montserrat-extrabold.woff") format("woff");
}
