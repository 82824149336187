.footer {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: $color-default-black;

  @media screen and (max-width: 700px) {
    height: auto;
    padding: 50px 0 130px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__descr {
    max-width: 400px;
    width: 100%;
    margin: 30px 0 0;
    font-style: normal;
    text-align: center;
    color: #ffffff;

    a {
      color: #ffffff;
    }
  }

  &__note {
    font-size: 14px;

    & a {
      color: #2ce329;
      text-decoration: underline;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    @media screen and (min-width: 1024px) {
      padding-bottom: 100px;
    }
  }

  &__social-item {
    padding: 10px;

    a {
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }

    use {
      fill: #1cace4;
    }
  }

  &__copyright {
    position: absolute;
    left: 50%;
    bottom: 85px;
    display: block;
    transform: translate(-50%, 0);
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-size: 11px;
    text-decoration: underline;
    color: #8c20d4;
    will-change: transform;

    &.active {
      animation: upCopyright 0.7s 0.3s forwards;
    }

    span {
      font-style: normal;
      font-family: "Arial Black", serif;
    }
  }

  &__ghost {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 2;
    will-change: transform;
    transform: translate(-50%, 170%);
    transition: transform 0.5s;

    &.active {
      transform: translate(-50%, 0);
    }
  }

  &.default {
    height: auto;
    padding-bottom: 108px;
    padding-top: 80px;
  }
}

.bg-gradient {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: linear-gradient(to right, #e2501a 0%, #c2f222 100%);
  background-size: 300% 300%;
  animation: background-shift 5s ease infinite;
  transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

@keyframes upGhost {
  0% {
    transform: translate(-50%, 170%);
  }

  100% {
    transform: translate(-50%, 0%);
  }
}

@keyframes upCopyright {
  0% {
    transform: translate(-50%, 0);
  }

  50% {
    transform: translate(-50%, -20px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes background-shift {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.Ansprechpartner-name {
  color: #ffffff;
  text-align: center;
}

.Ansprechpartner-name-prev {
  color: #ffffff;
  text-align: center;
}

[lang="de"] {
  .footer {
    &__Solutions {
      font-size: 12px;
    }
  }
}

[lang="en"] {
  .footer {
    &__Solutions {
      font-size: 12px;
    }
  }
}
