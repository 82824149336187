@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

// Mobile first

/* @mixin viewport--md {
  @media (min-width: $viewport--md) {
    @content;
  }
}

@mixin viewport--lg {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

@mixin viewport--xl {
  @media (min-width: $viewport--xl) {
    @content;
  }
} */
