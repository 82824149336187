.clients {
  position: relative;
  margin-top: 150px;
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #FFFF;

  @media screen and (max-width: 1023px) {
    margin-top: 70px;
    padding-bottom: 70px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    max-width: 974px;
    width: 100%;
    height: 3px;
    transform: translateX(-50%);
    border-bottom: 3px dashed #767d8f;
    opacity: 0.39;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 600px) {
      padding-bottom: 45px;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    padding: 40px 20px;

    @media screen and (max-width: 640px) {
      width: 50%;
      padding: 20px 10px;
    }

    @media screen and (max-width: 460px) {
      width: 100%;
      padding: 20px 10px;
    }
  }
}

/*partners section*/

.partners {
  position: relative;
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 100px;
  color: #ffff;

  @media screen and (max-width: 1023px) {
    margin-top: 0;
    padding-bottom: 70px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    max-width: 974px;
    width: 100%;
    height: 3px;
    transform: translateX(-50%);
    border-bottom: 3px dashed #767d8f;
    opacity: 0.39;
  }

  &__title {
    margin: 0;
    font-display: swap;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 600px) {
      padding-bottom: 45px;
    }

    & .slick-next::before,.slick-prev::before{
      color:azure;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    padding: 40px 20px;

    @media screen and (max-width: 640px) {
      width: 50%;
      padding: 20px 10px;
    }

    @media screen and (max-width: 460px) {
      width: 100%;
      padding: 20px 10px;
    }
    & img {
      margin: 0 auto;
    }
  }
}
