.container {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  //padding: 0 56px;

  @include viewport--lg {
    padding: 0 32px;
  }

  @include viewport--md {
    padding: 0 20px;
  }

}
