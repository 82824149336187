.visually-hidden,
input[type="file"].visually-hidden,
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.scroll-lock {
  overflow-y: auto;
  position: fixed;
}

.btn-reset {
  cursor: pointer;
  padding: 0;
  background: $color-transparent;
  border: none;
}
