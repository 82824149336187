@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.bx24_form_inline_2 {
  width: 100%;
}

div.crm-webform-default {
  border: none;
}

div.flexible-middle-width div.content-wrap {
  max-width: 707px !important;
}

div.crm-webform-main-container {
  padding: 0;
}

h2.crm-webform-header {
  margin: 0 0 20px;
  font-family: "Montserrat", "Arial", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
  color: #010002;
}

div.crm-webform-header-container {
  border: none;
  border-radius: 0;
  background: transparent;

  > div {
    font-size: 14px;
    text-align: center;
    color: #6b6c7e;
  }
}

div.crm-webform-body {
  padding: 0;
}

div.crm-webform-label-title-container {
  position: absolute;
  top: 15px;
  left: 51px;
  pointer-events: none;
  transition: top 0.3s, color 0.3s, opacity 0.3s;
}

fieldset.crm-webform-fieldset {
  div.crm-webform-field-string {
    label.crm-webform-input-label {
      &:before {
        width: 16px;
        height: 16px;
        background-image: url(../img/sprite/icon-user-blue.svg);
      }
    }

    div.crm-webform-group {
      margin-bottom: 35px;
    }
  }

  div.crm-webform-field-email {
    label.crm-webform-input-label {
      &:before {
        width: 22px;
        height: 15px;
        background-image: url(../img/sprite/icon-email.svg);
      }
    }
  }
}

label.crm-webform-label-content {
  background: transparent;
}

label.crm-webform-label-required-field {
  font-weight: 300;
  font-size: 15px;
  color: #014f80;
  opacity: 0.5;
  pointer-events: none;

  &::after {
    display: none;
  }
}

label.crm-webform-input-label {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    transition: background-image 0.3s;
  }
}

input.crm-webform-input {
  max-width: 100%;
  width: 100%;
  height: 50px;
  padding-left: 36px;
  border: 0;
  border-bottom: 2px solid #014f80;
  border-radius: 0;
  background: transparent;
  outline: 0;
  transition: border-bottom-color 0.3s;

  &:hover {
    border-bottom-color: #4042fb;
  }

  &::placeholder {
    opacity: 0;
  }
}

div.crm-webform-success,
div.crm-webform-active {
  div.crm-webform-label-title-container {
    top: 52px;
    color: #4042fb;
    opacity: 1;
  }

  input.crm-webform-input {
    border-bottom-color: #4042fb;
  }

  fieldset.crm-webform-fieldset {
    .crm-webform-field-string {
      label.crm-webform-input-label {
        &:before {
          background-image: url(../img/sprite/icon-user.svg);
        }
      }
    }

    .crm-webform-field-email {
      label.crm-webform-input-label {
        &:before {
          background-image: url(../img/sprite/icon-email-purple.svg);
        }
      }
    }
  }
}

div.crm-webform-error {
  div.crm-webform-label-title-container {
    top: 52px;
    color: #4042fb;
    opacity: 1;
  }

  fieldset.crm-webform-fieldset {
    div.row {
      &:nth-of-type(1) {
        label.crm-webform-input-label {
          &:before {
            background-image: url(../img/sprite/icon-user.svg);
          }
        }
      }

      &:nth-of-type(2) {
        label.crm-webform-input-label {
          &:before {
            background-image: url(../img/sprite/icon-email-purple.svg);
          }
        }
      }
    }
  }
}

i.crm-webform-icon {
  display: none;
}

fieldset.crm-webform-fieldset-footer {
  padding: 0;
}

div.crm-webform-agreement-modifier {
  margin: 0;
}

button.crm-webform-submit-button {
  display: inline-block;
  margin: 50px auto 0;
  font-weight: 700;
  height: 60px;
  padding: 0 45px;
  border: 0;
  border-radius: 4px;
  background-color: #4043ff;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  outline: 0;
  opacity: 1;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

div.crm-webform-agreement-modifier label.crm-webform-checkbox-container {
  align-content: center;
}

span.crm-webform-checkbox-icon-container {
  display: flex;
  align-items: center;
}

label.crm-webform-checkbox-container i {
  position: relative;
  top: 0;
  margin: 0;
  border: none;
  background: transparent;
  opacity: 0.5;
}

input.crm-webform-input + i:after {
  color: #014f80;
}

div.crm-webform-agreement-modifier a.crm-webform-checkbox-name {
  position: relative;
  margin: 0;
  padding-right: 20px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.07rem;
  line-height: 2;
  opacity: 0.5;
  color: #0f3857;
  transition: opacity 0.3s;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

b.tooltip {
  display: none;
}

.form-button-disabled {
  cursor: not-allowed;
  background: gray;
  display: none !important;
}
