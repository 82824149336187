.blog {
  margin: 0;
  padding: 0;
  min-height: 200px;
  padding: 20px;
  font-family: Montserrat;
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 800px) {
    padding: 0 0 40px;
  }

  &-leftcolumn {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }

    &-item {
      width: 50%;
      padding: 0 10px;
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    &-card {
      height: 100%;
      position: relative;

      border: 1px dashed rgba(121, 122, 252, 0.34);
      border-radius: 28px;
      background-color: rgba(19, 25, 36, 0.34);
      padding: 20px;
      margin-top: 20px;

      @media screen and (max-width: 800px) {
        padding-bottom: 40px;
      }

      & a.heading-link {
        display: block;
        font-display: swap;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 8px;
        color: #ffffff;
        text-decoration: none;
        margin-left: 95px;
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }

        @media screen and (max-width: 800px) {
          margin-left: 0;
        }
      }
      & time {
        display: block;
        margin-left: 95px;
        font-size: 14px;
        color: #e5771f;
        font-style: italic;

        @media screen and (max-width: 800px) {
          margin-left: 0;
        }
      }
      & .author {
        float: left;

        @media screen and (max-width: 800px) {
          position: absolute;
          bottom: 15px;
          left: 20px;
          width: 40px;
        }

        & img {
          max-width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }

      &-announcement {
        padding: 0;
        margin: 0;
        margin: 16px 0;
        clear: both;
        & p {
          width: auto;
          color: white;

          @media screen and (max-width: 800px) {
            font-size: 14px;
          }
        }
      }
      & a.button-link {
        position: absolute;
        bottom: 0;
        right: 0;
        white-space: nowrap;
        padding: 0;
        padding: 8px 40px;
        display: inline-block;
        border: 0;
        border-radius: 0 0 28px 0;
        background-color: #4043ff;
        color: azure;
        font-size: 14px;
        line-height: 1.2;
        outline: 0;
        transition: opacity 0.3s;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
      }
    }
  }
}

/*blog-announcements*/



   .announcements-title {
    margin: 0;
    font-weight: 700;
    font-size: 36px;
    color:#ffffff;
    line-height: 1.2;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }
  }

