.drupal-community {
  background-color: #fff;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;



  .block-title {
    margin: 0;
    font-display: swap;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }
  }

  &__description {
    margin: 0 auto 55px;
    font-weight: 500;
    font-size: 16px;
    color: #4f5a73;
    width: 70%;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 1023px) {
      font-size: 14px;
      text-align: left;
      width: auto;
      padding: 0 20px;
    }

    a {
      text-decoration: none;
      color: #19d3e6;
    }
  }
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  color: #050c33;
  width: 70%;
  padding: 0;

  &-item {
    list-style: none;
    display: inline-block;
    padding: 24px;
    text-align: center;
    width: 23.33333%;
    vertical-align: top;


    @media (max-width: 1650px) {
      width: 23.33333%;
    }
    @media (max-width: 1000px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.user-teaser-photo {
  margin-bottom: 12px;
  position: relative;
  display: inline-block;
  line-height: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 131px;
    height: 110px;
    background: url(../img/svg/icon-bg-4.svg) no-repeat center;
    background-size: contain;
    left: -50%;
    bottom: 3px;
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 5;
  }
}

.user-name a {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.37;
  color: #050c33;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 5px;
}

.user-post {
  font-size: 12px;
}

.footer-info {
  &__left {
    float: left;
    width: 100%;

    @media (max-width: 570px) {
      width: 100%;

      address {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__right {
    float: left;
    width: 50%;
    text-align: center;

    @media (max-width: 570px) {
      width: 100%;
    }
  }
}

.Ansprechpartner {
  margin-top: 11%;

  & img {
    margin-left: 15px;
    max-width: 50%;
    height: auto;

    @media (max-width: 570px) {
      margin-left: 0;
    }
  }
}
