.page {
  &__body {
    position: relative;
    background-color: #090E17;

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      left: 50%;
      max-width: 1200px;
      width: 100%;
      height: 1540px;
      transform: translateX(-50%);
      background-image: url('../img/bg/server-img.webp');
    }

    &.default {
      &::before {
        height: 100%;
      }
    }
  }
}

figure {
  margin: 0;
  padding: 0;
}

.main-btn {
  display: flex;
  //max-width: 170px;
  height: 60px;
  padding: 0 45px;
  border: none;
  border-radius: 4px;
  background-color: #4043FF;
  font-size: 14px;
  line-height: 1.2;
  color: #FFFFFF;
  outline: none;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  &--opacity {
    background-color: transparent;
    border: 1px solid #576078;
    color: #BDC3CA;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

button.main-btn {
  display: inline-block;
}

.nav-block {
  position: fixed;
  top: 50%;
  right: 77px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  mix-blend-mode: difference;

  @media screen and (max-width: 1300px){
    right: 20px;
  }

  @media screen and (max-width: 1024px){
    display: none;
  }

  &.dark {
    .nav-block__ghost {
      //fill: $color-default-black;
    }

    .nav-block__button {
      &.active {
        .btn-icon {
          opacity: 1;
        }
      }

      .start-icon,
      .end-icon {
        .start-oval-1 {
          fill: #000000;
        }

        .start-oval-2 {
          stroke: #000000;
        }
      }

      .btn-icon {
        fill: transparent;
        stroke: #000000;
        opacity: 0.5;

        .start-oval-1 {
          fill: #000000;
        }
      }
    }
  }

  &__ghost {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    fill: #FFFFFF;
    transition: opacity 0.1s;

    &.hidden {
      opacity: 0;
    }
  }

  &__button {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &.active {
      .start-icon {
        opacity: 1;

        .svg-gradient {
          display: block;
        }

        .start-oval-1 {
          shape-rendering: geometricPrecision;
          fill: url(#linear-gradient1);
        }

        .start-oval-2 {
          shape-rendering: geometricPrecision;
          stroke: url(#linear-gradient2);
          stroke-dasharray: 67;
          stroke-dashoffset: 139;
        }
      }

      .end-icon {
        opacity: 1;
        transform: scale(-1, -1);

        .svg-gradient {
          display: block;
        }

        .start-oval-1 {
          shape-rendering: geometricPrecision;
          fill: url(#linear-gradient3);
        }

        .start-oval-2 {
          shape-rendering: geometricPrecision;
          stroke: url(#linear-gradient4);
          stroke-dasharray: 88;
          stroke-dashoffset: 191;
        }
      }

      .btn-icon {
        opacity: 1;
      }
    }

    use {
      fill: #FFFFFF;
      opacity: .5;
    }

    .btn-icon {
      fill: transparent;
      stroke: #FFFFFF;
      opacity: .5;

      .start-oval-1 {
        fill: #FFFFFF;
      }
    }

    .start-icon,
    .end-icon {
      opacity: .5;

      .start-oval-1 {
        fill: #FFFFFF;
      }

      .start-oval-2 {
        fill: transparent;
        stroke: #FFFFFF;
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        transition: stroke-dasharray 0.3s;
      }

      .svg-gradient {
        display: none;
      }
    }
  }
}

.pacman {

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #FFC107;
    width: 50px;
    height: 25px;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    border-radius: 50px 50px 0 0;
    animation: up 0.2s infinite;
  }

  &::after {
    margin-top: -1px;
    border-radius: 0 0 50px 50px;
    animation: down 0.2s infinite;
  }

  &__wrapper {
    position: absolute;
    left: 20px;
    top: -40px;
    z-index: 2;
    width: 50px;
    height: 50px;
    transform: translateX(235%);
    //animation-name: packmanEat;
    animation-duration: 2s;
    //transition: transform 1s;
    will-change: transform;

    &.active {
      animation-name: packmanEat;
    }
  }

  &__inner {
    transform: scale(-1, 1);
  }
}


@keyframes down {
  0%,
  100% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(30deg);
  }
}

@keyframes up {
  0%,
  100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-30deg);
  }
}

@keyframes packmanEat {
  0% {
    transform: translateX(235%);
  }

  50% {
    transform: translateX(0);
  }

  65% {
    transform: translateX(50%);
  }

  85% {
    transform: translateX(75%);
  }

  100% {
    transform: translateX(235%);
  }
}

.parallax-item {
  transition: transform 0.3s;
}

.code-anim {
  @media screen and (max-width: 700px) {
    width: 25px;
    height: 20px;
    vertical-align: baseline !important;
  }
}
