#COOKIE_MESSAGE_CONTAINER{
  display: none;
  bottom: 0;
  //box-shadow: 0 0 30px rgb(136 104 104 / 50%);
}


.avia-cookiemessage-top {
  left: 0;
}
.avia-cookie-consent {
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, .85);
  z-index: 999;
  color: rgba(255, 255, 255, .9);
  padding: 1.1em;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  }
.responsive .container {
  max-width: 1310px;
}
.avia-cookie-consent .container {
  float: none!important;
  padding: 0!important;
}
.avia-cookiemessage-top {
  left: 0;
}
.avia-cookie-consent {
  width: 100%;
  position: fixed;
  background-color: rgba(0,0,0,.85);
  z-index: 999;
  color: rgba(255,255,255,.9);
  padding: 1.1em;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.avia-cookie-consent a.avia_cookie_infolink, .avia-cookie-consent p {
  margin-right: .5em;
}
.avia-cookie-consent p {
  margin: 0;
}
.avia-cookie-consent a.avia_cookie_infolink, .avia-cookie-consent p, .avia-cookie-consent .avia-cookie-consent-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: .05em;
}
.avia-cookie-consent .avia-cookie-consent-button {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  border: 2px solid transparent;
  color: #000;
  padding: 1.1em;
  min-width: 80px;
  border-radius: 3px;
  text-decoration: none;
  transition: all 0.3s ease-in;
  line-height: 1;
  white-space: nowrap;
  margin: 0 0 0 6px;
}
.avia-cookie-consent .avia-cookie-consent-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: .05em;
}
.avia-cookie-consent .avia-cookie-consent-button.av-extra-cookie-btn {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.avia-cookie-consent .avia-cookie-consent-button {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  border: 2px solid transparent;
  color: #000;
  padding: 1.1em;
  min-width: 80px;
  border-radius: 3px;
  text-decoration: none;
  transition: all 0.3s ease-in;
  line-height: 1;
  white-space: nowrap;
  margin: 0 0 0 6px;
}
.avia-cookie-consent .avia-cookie-consent-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: .05em;
}
.avia-cookie-consent .avia-cookie-consent-button {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  border: 2px solid transparent;
  color: #000;
  padding: 1.1em;
  min-width: 80px;
  border-radius: 3px;
  text-decoration: none;
  transition: all 0.3s ease-in;
  line-height: 1;
  white-space: nowrap;
  margin: 0 0 0 6px;
}
.avia-cookie-consent .avia-cookie-consent-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: .05em;
  width: 200px;
  margin: 0 auto, 10px;
  max-width: 100%;
  margin-right: 10px;
}
.avia_cookie_text{
  margin-bottom: 10px;
}

@media screen and (max-width: 690px) {
  .mobile_display{
    display: block;
    margin-bottom: 10px;

  }
  .avia-cookie-consent .avia-cookie-consent-button {
    cursor: pointer;
    background-color: #fff;
    border: 2px solid transparent;
    color: #000;
    padding: 1.1em;
    min-width: 80px;
    border-radius: 3px;
    text-decoration: none;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    line-height: 1;
    white-space: nowrap;
    width: 200px;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
