/**************************\
  Basic Modal Styles
\**************************/

.modal {
  //font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #FFFFFFff;
  padding: 30px 60px;
  max-width: 700px;
  width: 100%;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.b24-window {
  .b24-window-panel {
    background-color: #ffffffff;
    padding: 30px 60px;
    max-width: 700px;
    width: 100%;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    .b24-form-wrapper { 
      padding: 30px 60px; 
      
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
    }

    .b24-form {
      .b24-form-wrapper {
        box-shadow: none;
        border: none; 
        font-family: "Montserrat","Arial",sans-serif;
        width: 100%;
        max-width: 100%;   
        background: transparent; 
      }  

      &-state-container { 
        .b24-form-success {
          background-color: #fff;
        }
      }

      &-sign {
        display: none;
      }

      &-btn {
        margin: 8px auto 0;
        font-weight: 700;
        display: inline-block;
        font-family: "Montserrat","Arial",sans-serif;
        height: 60px;
        padding: 0 45px;
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.2;
        outline: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
        cursor: pointer;
        width: auto;
        
        &-block {
          text-align: center;
        } 
      } 

      &-header {
        padding: 0;
        border: none;
        margin-top: 30px;

        &-title {
          margin: 0 0 20px;
          font-weight: 800;
          font-size: 36px;
          line-height: 1.2;
          text-align: center;
          color: #010002;
          font-family: "Montserrat","Arial",sans-serif;
        }

        &-description {
          font-size: 14px;
          text-align: center;
          color: #6b6c7e;
          opacity: 1;
          margin-top: 14px;
          margin-bottom: 14px;
          line-height: 22px;
          font-family: "Montserrat","Arial",sans-serif;
        }
      }
       
      &-content {
        max-width: 707px;
        width: 100%;
        margin: 40px auto 0;
        padding: 0;
      }

      &-control {
        &-container {
          margin-bottom: 35px;
        }

        &-agreement {
          position: relative;
          margin: 25px 0 0;
          padding-right: 20px;
          font-size: 12px;
          line-height: 22;
          text-align: center;
          letter-spacing: .07rem;
          opacity: .5;
          color: #0f3857;
          -webkit-transition: opacity .3s;
          transition: opacity .3s;
          font-family: "Montserrat","Arial",sans-serif;
          
          .b24-form-field-agreement-link {
            font-family: "Montserrat","Arial",sans-serif;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: .07rem;
          }

          .b24-form-control-desc {
            opacity: 1;
          }

          input {
            position: absolute;
            top: 50%;
            right: -20px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%); 
          } 
        }
    
        &-string {
          position: relative; 

          &.b24-form-field-email {
            &:before {
              content: "";
              width: 22px;
              height: 15px;
              background-image: url(../img/sprite/icon-email.svg);
              -webkit-transition: background-image .3s;
              transition: background-image .3s;  
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }

          &.b24-form-field-name {
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              background-image: url(../img/sprite/icon-user-blue.svg);
              -webkit-transition: background-image .3s;
              transition: background-image .3s;  
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }

          input {
            width: 100%;
            height: 50px;
            padding-left: 36px;
            padding: 1px 0 1px 36px;
            border: 0;
            border-bottom: 2px solid #014f80;
            border-radius: 0;
            background: 0 0;
            outline: 0;
            -webkit-transition: border-bottom-color .3s;
            transition: border-bottom-color .3s; 
          }  

          &.b24-form-control-alert {
            input {
              border-color: red;
            }
          } 
        }

        &-alert-message {
          display: none;
        }

        &-required {
          display: none;
        }
 
        &-label {
          font-weight: 300;
          font-size: 15px;
          color: #014f80;
          opacity: .5;
          pointer-events: none;
          -webkit-transition: top .3s,color .3s,opacity .3s;
          transition: top .3s,color .3s,opacity .3s;
          padding: 0;
          font-family: "Montserrat","Arial",sans-serif;
          position: absolute;
          top: 15px;
          left: 36px;
          line-height: 22px;
          transform: none;
        }  

        &:focus {
          +.b24-form-control-label {
            font-size: 15px;
            top: 15px;
            left: 36px;
            transform: none;
            opacity: 0.1;
          }  
        }
      }  
    }
  }
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

