.experience {
  position: relative;
  z-index: 2;
  padding-top: 90px;
  padding-bottom: 45px;

  &__title {
    position: relative;
    margin-top: 0;
    margin-bottom: 10px;
    font-display: swap;
    font-weight: 400;
    font-size: 52px;
    letter-spacing: -0.056rem;
    line-height: 1.2;
    color: #ffffff;

    @media screen and (max-width: 700px) {
      font-size: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 30px;
      line-height: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: -90px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: #ef9f4d;
    }
  }

  &__icon-anim {
    display: inline-block;
  }

  &__descr {
    margin: 0;
    letter-spacing: 0.01rem;
    color: #ffffff;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 110px 0 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 1090px) {
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  &__icon {
    position: absolute;
    top: 4px;
    left: 12px;

    @media screen and (max-width: 768px) {
      position: relative;
      top: 0;
      left: 0;
    }

    use {
      fill: #29d626;
    }
  }

  &__item {
    position: relative;
    width: calc(100% / 3);
    margin-bottom: 120px;
    padding-right: 20px;
    padding-left: 58px;

    @media screen and (max-width: 1090px) {
      max-width: 350px;
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 60px;
      padding-right: 0;
      padding-left: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 45px;
      width: 3px;
      height: 100%;
      transform: translateY(-50%);
      border-left: 3px dashed #166e15;

      @media screen and (max-width: 768px) {
        left: 0px;
      }
    }

    &-title {
      margin-bottom: 5px;
      font-size: 23px;
      line-height: 1.2;
      color: #2ce429;
    }

    &-text {
      width: 100%;
      margin: 0;
      color: #2ce429;

      @media screen and (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
}

.terminal-underline {
  opacity: 1;
  animation: blink 0.8s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
